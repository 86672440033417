import breakpoints from "styled-components-breakpoints";

export default breakpoints({
	xxs: 0,
	xs: 320,
	s: 576,
	m: 768,
	l: 992,
	xl: 1200,
});
